import { lazy } from 'react';
import i18next from 'i18next';
import { ERROR_QUERY_PARAMS } from '~constants/general';
import CorporativoF2 from '~screens/TermsF2/corporativo';

import PATHS from './paths';

const Terms = lazy(() => import('../../screens/Terms/General'));
const TermsDDJJ = lazy(() => import('../../screens/Terms/DDJJ'));
const TermsF2 = lazy(() => import('../../screens/TermsF2'));
const TermsCorporative = lazy(() => import('../../screens/Terms/Corporative'));
const Terms8430 = lazy(() => import('../../screens/Terms/8430'));
const TermsOthers = lazy(() => import('../../screens/Terms/Others'));
const TermsOthers6030 = lazy(() => import('../../screens/Terms/6030'));
const Terms8260 = lazy(() => import('../../screens/Terms/8260'));
const Home = lazy(() => import('../../screens/Dashboard'));
const Missing = lazy(() => import('../../screens/Missing'));
const BeneficiaryEndScreen = lazy(() => import('../../screens/BeneficiaryEnd'));
const OnFormWizard = lazy(() => import('~screens/ON/OnFormWizard'));
const F2FormWizard = lazy(() => import('~screens/F2/F2FormWizard'));
const GeneralRoutes = lazy(() => import('~screens/ON/generalRoutes'));
const Questions = lazy(() => import('~screens/Dashboard/screens/Questions/questions'));
const ValidateAffiliationOSDE = lazy(() => import('../../screens/ValidateAffiliation/OSDE'));
const ValidateAffiliationON = lazy(() => import('../../screens/ValidateAffiliation/ON'));

export const ROUTES = [
  // TODO: Delete next route
  {
    exact: true,
    path: PATHS.validate,
    component: ValidateAffiliationOSDE,
    title: i18next.t('Routes:validateAffiliationTitle'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.validateON,
    component: ValidateAffiliationON,
    title: i18next.t('Routes:validateAffiliationTitle'),
    withGeneralContainer: false
  },
  {
    exact: false,
    path: `${PATHS.form}/:token`,
    component: Home,
    title: i18next.t('Routes:homeTitle'),
    withGeneralContainer: true
  },
  // {
  //   exact: false,
  //   path: `${PATHS.form}/:token/resumen-autogestion`,
  //   component: Home,
  //   title: i18next.t('Routes:homeTitle'),
  //   withGeneralContainer: true
  // },
  {
    exact: true,
    path: PATHS.terms,
    component: Terms,
    title: i18next.t('Routes:termsAndConditions'),
    description: i18next.t('Routes:termsAndConditions'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.termsDDJJ,
    component: TermsDDJJ,
    title: i18next.t('Routes:termsAndConditions'),
    description: i18next.t('Routes:termsAndConditions'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.termsCorporative,
    component: TermsCorporative,
    title: i18next.t('Routes:termsAndConditions'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.terms8430,
    component: Terms8430,
    title: i18next.t('Routes:termsAndConditions'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.terms8260,
    component: Terms8260,
    title: i18next.t('Routes:termsAndConditions'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.termsF2,
    component: TermsF2,
    title: i18next.t('Routes:termsAndConditions'),
    description: i18next.t('Routes:termsAndConditions'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.termsF2Corporativo,
    component: CorporativoF2,
    title: i18next.t('Routes:termsAndConditions'),
    description: i18next.t('Routes:termsAndConditions'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.termsOthers,
    component: TermsOthers,
    title: i18next.t('Routes:termsAndConditions'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.termsOthers6030,
    component: TermsOthers6030,
    title: i18next.t('Routes:termsAndConditions'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.missing,
    component: Missing,
    title: i18next.t('Routes:missing'),
    withGeneralContainer: true
  },
  {
    exact: true,
    path: PATHS.beneficiaryCancel,
    component: BeneficiaryEndScreen,
    title: i18next.t('Routes:missing'),
    withGeneralContainer: true
  },
  {
    exact: false,
    path: '/on/:token',
    component: OnFormWizard,
    withGeneralContainer: false
  },
  {
    exact: false,
    path: '/general/on',
    component: GeneralRoutes,
    withGeneralContainer: false
  },
  {
    exact: false,
    path: `${PATHS.f2}/:token`,
    component: F2FormWizard,
    withGeneralContainer: false
  },
  {
    exact: false,
    path: `/:token${PATHS.questions}`,
    component: Questions,
    withGeneralContainer: false
  },
  {
    exact: true,
    path: `${PATHS.f2}/`,
    component: F2FormWizard,
    redirectTo: () => `${PATHS.f2}/:token/inicio`
  },
  {
    exact: true,
    path: PATHS.thanks,
    redirectTo: () => `/:token${PATHS.questions}${PATHS.thanks}`
  },
  {
    exact: false,
    path: PATHS.default,
    redirectTo: () => `${PATHS.missing}${ERROR_QUERY_PARAMS.e404}`
  }
];
