import i18next from 'i18next';

i18next.addResources('es', 'FinishSwornDeclaration', {
  disclaimerAccept: 'Acepto las condiciones',
  subtitle: 'Declaración Jurada de Salud',
  subtitleDDJJ: 'Estado de Salud Actual',
  subtitleOTHERS: 'Solicitud de trámite',
  disclaimerFirst: `Declaro bajo juramento que en la presente informé la totalidad de mis antecedentes de salud y/o de cada uno de los integrantes de mi grupo familiar, no habiendo omitido dato alguno, estando por lo tanto OSDE facultado para resolver el vínculo en caso de falsedad en los términos del Dec. Reg. 1993/11, art. 9, inc. b), el que también declaro conocer.
  
Declaro que he tomado conocimiento y en consecuencia acepto las `,
  disclaimerFirstDDJJ: `Declaro bajo juramento que en la presente informé la totalidad de mis antecedentes de salud y/o de cada uno de los integrantes de mi grupo familiar, no habiendo omitido dato alguno, estando por lo tanto OSDE facultado para resolver el vínculo en caso de falsedad en los términos del Dec. Reg. 1993/11, art. 9, inc. b), el que también declaro conocer.
  
Declaro que he tomado conocimiento y en consecuencia acepto las `,
  terms: 'condiciones generales',
  disclaimerSecond: ` en relación a la solicitud de afiliación.
  
Autorizo expresamente a OSDE a requerir información médica referida a mi persona y/o grupo familiar a cualquier prestador y/o institución de salud.`,
  disclaimerSecondDDJJ: `.
  
Autorizo expresamente a OSDE a requerir información médica referida a mi persona y/o grupo familiar a cualquier prestador y/o institución de salud.`
});
