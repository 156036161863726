/* eslint-disable max-lines */
import { FormikProps, FieldInputProps } from 'formik';
import { UploadFile } from 'antd/lib/upload/interface';
import { InputSizes } from '~constants/sizes';

export type Nullable<T> = T | null;

export interface ContractHolderData {
  datos_filiatorios: {
    numero_ic: number;
    nro_socio: string;
    plan_servicio: number;
    nombre: string;
    apellido: string;
    fecha_nacimiento: string;
    estado_civil: string;
    cuil: Nullable<number>;
    dni: Nullable<number>;
    sexo: string;
    nacionalidad: string;
    condicion_iva: string;
    empresa: string;
    cuit_empresa: string;
    sueldo: number;
  };
  domicilio: {
    calle: string;
    nro: number;
    piso: Nullable<number>;
    dpto: Nullable<string>;
    localidad: Nullable<string>;
    provincia: string;
    cod_postal: number;
    telefono_principal: Nullable<string>;
    telefono_alternativo: Nullable<string>;
    email: Nullable<string>;
    email_alternativo: Nullable<string>;
  };
}

export interface CommonInputLabelProps {
  label?: string | object;
  labelClassName?: string;
  sublabel?: string | object;
  sublabelClassName?: string;
  size?: InputSizes;
  isOptional?: boolean;
  labelContainerClassName?: string;
  labelSpanClassName?: string;
  textTooltip?: string;
}

export interface CommonInputProps {
  onBlur?: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  name: string;
  placeholder: string;
  value?: string | number;
  withClear?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  error?: string | object;
  errorClassName?: string;
  inputClassName?: string;
  form: FormikProps<any>;
  field: FieldInputProps<any>;
  scrollOnAppear?: boolean;
  forceScrollOnAppear?: boolean;
}

export type OptionType = {
  label: string;
  value: string | number;
  disabled?: boolean;
  disableAll?: boolean;
};

export interface CommonInputOptionsProp {
  options: Array<OptionType>;
}

export type RefType = HTMLDivElement | HTMLInputElement | null;

export interface RouteProps {
  match: { params: { token: string; type: string; idtx?: string } };
  location: { pathname: string; search: string };
}

export type i18nStringType = string | object | (string | object)[];

type Key = string | number;
export type GenericObjectInterface<T> = {
  [key in Key]: T;
};

export type GenericObjectArray = Array<GenericObjectInterface<any>>;

export interface FormFieldType {
  value: string | number | Array<string | number>;
  formatted: string | number | Array<string | number>;
}

export interface SectionInterface {
  label: i18nStringType;
  value: any;
  pascalCase?: boolean;
}

export interface PathologyInterface extends GenericObjectInterface<any> {
  hadPathology: FormFieldType;
  hadVision: FormFieldType;
  whichOtherVision: FormFieldType;
  whichHereditary: FormFieldType;
  whichPsychiatric: FormFieldType;
  whichAudition: FormFieldType;
  whichMyopiaVision: FormFieldType;
}

export interface SurgeriesInterface extends GenericObjectInterface<any> {
  hadSurgeries: FormFieldType;
  whichSurgeries: FormFieldType;
  whichCardiology: FormFieldType;
  whichAbdominal: FormFieldType;
  whichGinecology: FormFieldType;
  whichNervous: FormFieldType;
  whichEndocrine: FormFieldType;
  whichSurgeryOther: FormFieldType;
  hadProsthetic: FormFieldType;
  whichTraumatology: FormFieldType;
  whichAesthetic: FormFieldType;
}

export type ErrorType = {
  errors?: Array<{ code: string | number }>;
  fault?: { faultstring: string; detail: { errorcode: string } };
};

export type WhoType = { key: string; path: string; index: number };

export interface FileType extends UploadFile<any> {
  nameToSave: string;
  name: string;
  serviceUrl?: string;
}

export enum APPLICATION_TYPES {
  F1 = 'f1_application',
  FULL = 'full_application',
  BENEFICIARY = 'beneficiary_application',
  DDJJ_APPLICATION = 'ddjj',
  OTHERS = 'others'
}

export const APPLICATION_SUBTYPES = {
  CAMBIO_DE_CONDICION_IVA: '0031',
  CAMBIO_DE_PLAN: '0049',
  BAJA: '0050',
  PLURIEMPLEO: '0005-A',
  SUMATORIO_DE_APORTES: '0005-B',
  TRASPASOS_JERARQUIZACION: '0037',
  CAMBIO_GRUPO_DIRECTO_EMPRESA: '0052',
  LICENCIAS_SUBSIDIOS: '0008',
  SUBCIDIO_POR_FALLECIMIENTO: '0054',
  PLAN_ESPECIAL: '',
  CAMBIO_EMPLEADOR_APORTE_LEY: '0051',
  PLAN_6030: '0053'
};
// eslint-disable-next-line id-length
export const APPLICATION_SUBTYPES_TRANSLATIONS = {
  [APPLICATION_SUBTYPES.CAMBIO_DE_CONDICION_IVA]: 'Cambio de condición de IVA',
  [APPLICATION_SUBTYPES.CAMBIO_DE_PLAN]: 'Cambio de plan',
  [APPLICATION_SUBTYPES.BAJA]: 'Baja',
  [APPLICATION_SUBTYPES.PLURIEMPLEO]: 'Pluriempleo',
  [APPLICATION_SUBTYPES.SUMATORIO_DE_APORTES]: 'Sumatoria de aportes',
  [APPLICATION_SUBTYPES.TRASPASOS_JERARQUIZACION]: 'Traspasos / Jerarquización',
  [APPLICATION_SUBTYPES.CAMBIO_GRUPO_DIRECTO_EMPRESA]: 'Cambio a grupo directo por empresa',
  [APPLICATION_SUBTYPES.LICENCIAS_SUBSIDIOS]: 'Licencias / Subsidios',
  [APPLICATION_SUBTYPES.SUBCIDIO_POR_FALLECIMIENTO]: 'Subsidio por fallecimiento',
  [APPLICATION_SUBTYPES.PLAN_ESPECIAL]: 'Plan especial / Temporal',
  [APPLICATION_SUBTYPES.CAMBIO_EMPLEADOR_APORTE_LEY]: 'Cambio empleador solo aporte de ley',
  [APPLICATION_SUBTYPES.PLAN_6030]: 'Plan 6-030'
};

export const PLAN_TYPES = {
  Binario: 'Binario',
  Corportativo: 'Corporativo'
};

export const COMPANY_PLAN_TYPES = {
  P210: '210',
  P310: '310',
  P410: '410',
  P450: '450',
  P510: '510',
  P8360: '8-360',
  P8430: '8-430',
  P8260: '8-260'
};

/* eslint-disable quote-props */
export const PLAN_TYPES_CODED: { [key: string]: string } = {
  '210': 'A',
  '310': 'B',
  '410': 'C',
  '450': 'D',
  '510': 'E',
  '8-360': 'F',
  '8-430': 'G',
  '8-260': 'H'
};
/* eslint-disable quote-props */

export const APPLICATION_TYPES_CODED: { [key: string]: string } = {
  // eslint-disable-next-line prettier/prettier
  'f1_application': 'f1',
  // eslint-disable-next-line prettier/prettier
  'full_application': 'f1-ddjj',
  // eslint-disable-next-line prettier/prettier
  'beneficiary_application': 'beneficiario'
};
export const AFFILIATE_TYPES_CODED: { [key: string]: string } = {
  direct: 'directo',
  // eslint-disable-next-line @typescript-eslint/camelcase
  direct_group: 'directoempresa',
  mandatory: 'obligatorio',
  monotax: 'monotributo',
  agreement: 'convenio',
  complement: 'complementacion'
};
/* eslint-disable-next-line prettier/prettier*/
export const TRACK_PAGE_VIEW = 'trackPageview';
export const TRACK_PAGE_VIEW_PAGE_NAME = '/alta-online/formulario-enviado';
export const TRACK_PAGE_VIEW_FORM = 'Formulario enviado';

export enum AFFILIATE_TYPES {
  DIRECT = 'direct',
  DIRECT_GROUP = 'direct_group',
  MANDATORY = 'mandatory',
  MONOTAX = 'monotax',
  AGREEMENT = 'agreement',
  COMPLEMENT = 'complement',
  CONVENIO8360 = 'convenio_8-360',
  OBLIGATORIO8360 = 'obligatorio_8-360',
  CONVENIO8430 = 'convenio_8-430',
  OBLIGATORIO8430 = 'obligatorio_8-430',
  CONVENIO8260 = 'convenio_8-260',
  OBLIGATORIO8260 = 'obligatorio_8-260'
}

export enum APPLICATION_SUBTYPES_ENUM {
  CAMBIO_DE_CONDICION_IVA = '0031',
  CAMBIO_DE_PLAN = '0049',
  BAJA = '0050',
  PLURIEMPLEO = '0005-A',
  SUMATORIO_DE_APORTES = '0005-B',
  TRASPASOS_JERARQUIZACION = '0037',
  CAMBIO_GRUPO_DIRECTO_EMPRESA = '0052',
  LICENCIAS_SUBSIDIOS = '0008',
  SUBCIDIO_POR_FALLECIMIENTO = '0054',
  PLAN_ESPECIAL = '',
  CAMBIO_EMPLEADOR_APORTE_LEY = '0051',
  PLAN_6030 = '0053'
}

export enum HIRING_MODES {
  APO = 'APO',
  PLA = 'PLA'
}
export type CompleteNameType = { name: FormFieldType; surname: FormFieldType };

export type F1PersonData = {
  PERSONAL_DATA: CompleteNameType;
  FAMILY_DATA: { [key: string]: any; couple?: Array<CompleteNameType>; sons?: Array<CompleteNameType> };
  PLAN_DATA_PLA: any;
};

export enum PAYMENT_TYPES {
  CREDIT_CARD = 'creditCard',
  BANK_ACCOUNT = 'bankAccount',
  OPTIONAL = ''
}

export interface IVBSteps {
  capturaDocumento: {
    iniciado: boolean;
    terminado: boolean;
    cantidadReintentos: number;
    cantidadIntentos: number;
    idTx: string;
    terminadoConError: boolean;
    error: string;
    historial?: Array<ActionHistoryVB>;
  };
  capturaManual: {
    iniciado: boolean;
    terminado: boolean;
    cantidadReintentos: number;
    cantidadIntentos: number;
    terminadoConError: boolean;
    error: string;
    historial?: Array<ActionHistoryVB>;
  };
  pruebaDeVida: {
    iniciado: boolean;
    terminado: boolean;
    cantidadReintentos: number;
    cantidadIntentos: number;
    idTx: string;
    terminadoConError: boolean;
    error: string;
    historial?: Array<ActionHistoryVB>;
  };
  validacionIdentidad: {
    iniciado: boolean;
    terminado: boolean;
    cantidadReintentos: number;
    cantidadIntentos: number;
    idTx: string;
    terminadoConError: boolean;
    error: string;
    historial?: Array<ActionHistoryVB>;
  };
  datosIndividuo: {
    capturaDocDecision: string;
    capturaDocIdtx: string;
    capturaDocManualDecision: string;
    nTramite: string;
    pruebaVidaDecision: string;
    pruebaVidaIdtx: string;
    citizenId: string;
    nationality: string;
    nombres: string;
    apellidos: string;
    dni: string;
    sexo: string;
    string: string;
    ejemplar: string;
    type: string;
    fechaEmision: string;
    info1: string;
    infoPdf: string;
    info3: string;
    info2: string;
    paisEmision: string;
    fechaExpiracion: string;
  };
  procesoEvaluado: boolean;
  correspondeProceso: boolean;
  procesoTerminado: boolean;
  correspondeCapturaManual: boolean;
  correspondeValidacionIdentidad: boolean;
  procesoTerminadoconError: boolean;
}

export interface BeginApplicationData {
  id: number;
  applicationType: APPLICATION_TYPES;
  affiliateType: AFFILIATE_TYPES;
  applicationSubtype: APPLICATION_SUBTYPES_ENUM;
  companyPlan: string;
  hiringMode: string;
  increasePlanAllowed: boolean;
  isPaymentResponsible: boolean;
  vbSteps: IVBSteps;
  datosTitularContrato: object;
  dniTitular: Nullable<string>;
  filtroSolicitudes: Nullable<any>;
  source: string;
}

export enum PLANS {
  PLAN360 = '8-360',
  PLAN430 = '8-430',
  PLAN260 = '8-260'
}

export interface CaptureData {
  dni: string;
  nombre: string;
  apellido: string;
  sexo: string;
  fechaNac: string;
  fechaEmision: string;
  numeroTramite: string;
}

export enum VB_FILE_NAMES {
  FRONT = 'frente',
  BACK = 'dorso',
  FACE = 'rostro'
}

export const VB_FILES_DNI_LENGTH = 2;

export enum PARTNER_TYPES {
  SIN_APORTE = 'Directorio o adherentes',
  CON_APORTE = 'En relacion de dependencia',
  AMBOS = 'Ambos'
}

export type StepVB = 'capturaDocumento' | 'capturaManual' | 'pruebaDeVida' | 'validacionIdentidad';

export type OriginActionVB = 'Idemia' | 'Alta Online';

export interface ActionHistoryVB {
  fecha: string;
  origen: OriginActionVB;
  mensaje: string;
  codigo: string;
}

export interface ActionHistoryCreateVB {
  origen: OriginActionVB;
  mensaje: string;
  codigo: string;
}

export type Product = 'OSDE' | 'F2';

declare global {
  interface Window {
    config: any;
  }
}
